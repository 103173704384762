import { useMemo } from 'react';

export const unMemoizedIsEmbedded = () => {
  const urlParams2 = new URLSearchParams(window.location.search.slice(1));
  return !!urlParams2.get('is_embedded');
};

export const unMemoizedOpenAdsInNewTab = () => {
  const urlParams2 = new URLSearchParams(window.location.search.slice(1));
  return !!urlParams2.get('open_in_new_tab');
};

export const unMemoizedReferer = () => {
  const urlParams2 = new URLSearchParams(window.location.search.slice(1));
  return urlParams2.get('referer');
};

const isEmbedded = () => useMemo(unMemoizedIsEmbedded, []);

export const openAdsInNewTab = () => useMemo(unMemoizedOpenAdsInNewTab, []);

export const useReferer = () => useMemo(unMemoizedReferer, []);

export default isEmbedded;

