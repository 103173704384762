
import { searchClient } from '../providers/searchProvider';
import getAdId from '../helpers/getAdId';
import { createSharedResource } from '../helpers/sharedResource';
import { indices } from '../helpers/indices';

const indexName = indices.vehicles;
const fetchObject = async (objectID) => {
    const res = await searchClient.customRequest({
        method: 'GET',
        path: `/1/indexes/${indexName}/${encodeURIComponent(objectID)}`,
        cacheable: true,
        data: {},
    });
    return res;
};

export const adPreviewItem = {
    "id": 95883597,
    "title": "Iveco Daily IVD4855",
    "description": null,
    "location": "STAD",
    "license_plate": "IVD4855",
    "odometer": 12,
    "odometer_km": 12,
    "nap": 0,
    "odometer_unit": "km",
    "registration_date": 1645660800,
    "model_id": 1133,


    "vat": 9219,
    "vat_or_margin": "VAT",
    "purchase_price": 43898,
    "type": "commercial",
    "state": "new",
    "marketing_name": "Iveco Daily 35S18HA 3.0 L4H2 16M3 180pk | Navigatie",
    "seat_count": null,
    "body_type": "Overig",
    "body_color": "Zilver metallic",
    "short_color": "zilver",
    "upholstery": "Stof",
    "fuel_type": "D",
    "range": null,
    "transmission": "A",
    "num_gears": 8,
    "power_hp": 179,
    "power_kw": 132,
    "num_cylinders": 4,
    "cylinder_capacity": 2998,
    "maximum_weight": null,
    "weight_empty": null,
    "payload": null,
    "trailer_load_braked": null,
    "trailer_load_unbraked": null,
    "fuel_capacity": null,
    "fuel_consumption_avg": null,
    "fuel_consumption_city": null,
    "fuel_consumption_highway": null,
    "top_speed": null,
    "acceleration": null,
    "energy_label": null,
    "co2_emission": null,
    "emission_class": null,
    "remarks": "",
    "vehicle_tax": 21,
    "road_tax_min": 0,
    "road_tax_max": 0,
    "delivery_costs": null,
    "addition_percentage": 0,
    "status": "A",
    "doors_count": 4,
    "max_lease_amount": 43898,
    "price_ex_vat": 43898,
    "price_incl_vat": 53117,
    "price_margin": null,
    "dealer_id": 9,
    "length_category": null,
    "height_category": null,
    "default_monthly_payment": 674.37,
    "maximum_final_payment": 6584.7,
    "accessories": [{
        "name": "16 inch lichtmetalen velgen",
    }, {
        "name": "Achteruitrijcamera",
    }, {
        "name": "Adaptive cruise control",
    }, {
        "name": "automatische airco",
    }, {
        "name": "Bluetooth telefoonverbinding",
    }, {
        "name": "elektrische ramen voor",
    }, {
        "name": "Elektrisch verstelbare buitenspiegels",
    }, {
        "name": "Navigatie",
    }, {
        "name": "startblokkering",
    }, {
        "name": "Trekhaak",
    }, {
        "name": "Verwarmde buitenspiegels",
    }, {
        "name": "verwarmde voorruit",
    }],
    "make": {
        "id": 104,
        "name": "Iveco",
        "logo": null,


        "laravel_through_key": "1133"
    },
    "model": {
        "id": 1133,
        "name": "Daily",
        "make_id": "104",


    },
    "videos": [],
    "dealer": {
        "id": 9,
        "name": "Test verkoper",
        "email": "test@example.com",
        "city": "STAD",
        "street": "Straatweg 123",
        "postal_code": "4567AB",


    },
    "warranties": [],
    "media": [{
        "order_column": "0",
        "original_url": "https://d7cvm8ak29oyx.cloudfront.net/images/3845/image1.jpg",
        "preview_url": "https://d7cvm8ak29oyx.cloudfront.net/images/3845/conversions/image1-preview.jpg"
    }, {
        "order_column": "1",
        "original_url": "https://d7cvm8ak29oyx.cloudfront.net/images/3846/image2.jpg",
        "preview_url": "https://d7cvm8ak29oyx.cloudfront.net/images/3846/conversions/image2-preview.jpg"
    }, {
        "order_column": "2",
        "original_url": "https://d7cvm8ak29oyx.cloudfront.net/images/3847/image3.jpg",
        "preview_url": "https://d7cvm8ak29oyx.cloudfront.net/images/3847/conversions/image3-preview.jpg"
    }, {
        "order_column": "3",
        "original_url": "https://d7cvm8ak29oyx.cloudfront.net/images/3848/image4.jpg",
        "preview_url": "https://d7cvm8ak29oyx.cloudfront.net/images/3848/conversions/image4-preview.jpg"
    }, {
        "order_column": "4",
        "original_url": "https://d7cvm8ak29oyx.cloudfront.net/images/3849/image5.jpg",
        "preview_url": "https://d7cvm8ak29oyx.cloudfront.net/images/3849/conversions/image5-preview.jpg"
    }, {
        "order_column": "5",
        "original_url": "https://d7cvm8ak29oyx.cloudfront.net/images/3850/image6.jpg",
        "preview_url": "https://d7cvm8ak29oyx.cloudfront.net/images/3850/conversions/image6-preview.jpg"
    }],
    "_tags": ["App\\Models\\Vehicle::95883597"],
    "objectID": "App\\Models\\Vehicle::95883597",

}

const getAdDetail = createSharedResource('adDetail', async (id) => {
    if (id === undefined || id === null || id === "__DEFAULT") return null;
    const objectID = `App\\Models\\Vehicle::${id}`;
    return fetchObject(objectID)
}, () => getAdId(), { validFor: 1000 * 60 * 60 * 12, preview: adPreviewItem });

export default getAdDetail;