import { adRegex } from "../helpers/url";

const updateVdpCanonicals = () => {
    const { pathname } = window.location;
    const adRegexMatch = pathname.match(adRegex);

    if (!adRegexMatch) return

    var canonical = document.querySelector("link[rel='canonical']");
    if (!canonical) return

    const newCanonical = /^https:\/\/[^/]*\/[^/]*\/[^/]*/.exec(window.location.href)?.[0];
    canonical.setAttribute('href', newCanonical);

}

export default updateVdpCanonicals;