import React from 'react';
import StyledErrorBoundary from './ErrorBoundary.style';
import * as Sentry from '@sentry/react';

const debug = process.env.REACT_APP_DEBUG === '1';

const ErrorBoundary = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, eventId, resetError }) =>
        debug ? (
          <StyledErrorBoundary>
            <h2>
              {error.toString()} (event: {eventId})
            </h2>
            <pre>{componentStack}</pre>
          </StyledErrorBoundary>
        ) : (
          <></>
        )
      }
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
export default ErrorBoundary;
/*
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //TODO: implement Sentry
    if (debug) return;
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.error) return null;
    if (props.error !== state.error) {
      if (props.error && debug) {
        console.error(props.error);
      }
      return {
        error: props.error,
      };
    }

    return null;
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      if (debug) {
        try {
          return (
            <StyledErrorBoundary>
              <h2>{this.state.error?.toString()}</h2>
              <pre>{this.state.error?.stack}</pre>
            </StyledErrorBoundary>
          );
        } catch (e) {
          // no-op
        }
      }
      return <></>;
    }

    return this.props.children;
  }
}
*/
