import GetUtmAndGclidParams from '../helpers/GetUtmAndGclidParams';
import Cookies from "js-cookie"
const saveUtmVars = () => {
    if (window.Cookiebot?.consented) {
        acceptHandler()
    } else {
        window.addEventListener('CookiebotOnAccept', acceptHandler)
    }
}

const acceptHandler = () => {
    const params = GetUtmAndGclidParams();
    if (params) {
        Object.keys(params).forEach(key => {
            if (key && params[key]) {
                Cookies.set(key, params[key]);
            }
        });
    }
}

export default saveUtmVars;