import { useMemo } from 'react';
import { colors } from '../../theme/Theme';
import { darken, getLuminance, lighten } from 'polished';
import { unMemoizedIsEmbedded } from './isEmbedded';

export const unMemoizedHasCustomTheme = () => {
  if (!unMemoizedIsEmbedded()) return 0;

  const urlParams = new URL(window.location).searchParams;

  let colorFtn = [lighten, darken];

  const themeParams = {
    background_color: {
      handle: 'white',
    },
    secondary_background_color: {
      handle: 'aliceBlue',
      related: [() => (colors.quartz = colorFtn[1](0.1, colors.aliceBlue))],
    },
    primary_color: {
      handle: 'pacificBlue',
      related: [() => (colors.skyBlue = colorFtn[0](0.2, colors.pacificBlue))],
    },
    secondary_color: {
      handle: 'bahamaBlue',
      related: [
        () => (colors.bahamaBlueLight = colorFtn[0](0.2, colors.bahamaBlue)),
        () => (colors.blueCharcoal = colorFtn[1](0.3, colors.bahamaBlue)),
      ],
    },
    text_color: {
      handle: 'trout',
      related: [() => (colors.dimGray = colorFtn[0](0.2, colors.trout))],
    },
    buttons_color: {
      handle: 'emerald',
    },
  };

  // urlThemeParam has the shape:
  // [
  //   ['primary_color': 'FF0000'],
  //   ...
  // ]
  const urlThemeParams = Array.from(urlParams).filter((param) => {
    // If background color is dark
    if (param[0] === "background_color" && getLuminance(`#${param[1]}`) < 0.5) {
      colorFtn = colorFtn.reverse();
    }

    return Object.keys(themeParams).includes(param[0])
  }
  );

  for (const [key, value] of urlThemeParams) {
    colors[themeParams[key].handle] = `#${value}`;
    themeParams[key]?.related?.forEach(elm => elm());
  }

  return urlThemeParams?.length;
};

const hasCustomTheme = () => useMemo(unMemoizedHasCustomTheme, []);

export default hasCustomTheme;
