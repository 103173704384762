import { useTheme } from "@emotion/react"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Theme } from "../../theme/Theme"
/**
 * @typedef {'desktopBigger'|'desktopBig'|'desktop'|'desktopSmall'|'laptop'|'tablet'|'mobile'} BreakpointName
 */

class Breakpoint {
    /**
     * @param {BreakpointName} name 
     * @param {typeof Theme} theme 
     */
    constructor(name, theme) {
        this.name = name
        this.breakpoints = [...theme.breakpoints.map(i => i.name), "mobile"]
    }
    /**
     * Determines whether the viewport is equal to the given breakpoint
     * @param {BreakpointName} name 
     * @returns {boolean}
     */
    is(name) {
        return name === this.name
    }
    /**
     * Determines whether the viewport is larger than or equal to the given breakpoint
     * @param {BreakpointName} name 
     * @returns {boolean}
     */
    equalsOrUp(name) {
        return this.breakpoints.indexOf(name) >= this.breakpoints.indexOf(this.name)
    }
    /**
     * Determines whether the viewport is larger than the given breakpoint
     * @param {BreakpointName} name 
     * @returns {boolean}
     */
    isUp(name) {
        return this.breakpoints.indexOf(name) > this.breakpoints.indexOf(this.name)
    }
    /**
     * Determines whether the viewport is smaller than or equal to the given breakpoint
     * @param {BreakpointName} name 
     * @returns {boolean}
     */
    equalsOrDown(name) {
        return this.breakpoints.indexOf(name) <= this.breakpoints.indexOf(this.name)
    }
    /**
     * Determines whether the viewport is smaller than the given breakpoint
     * @param {BreakpointName} name 
     * @returns {boolean}
     */
    isDown(name) {
        return this.breakpoints.indexOf(name) < this.breakpoints.indexOf(this.name)
    }

    /**
     * Selects an item from a responsive list
     * @template {T}
     * @param {{mobile:T} & Partial<{[name in BreakpointName]: T}>} props 
     * @returns {T}
     */
    select(props) {
        const currentIx = this.breakpoints.indexOf(this.name);
        for (let ix = currentIx; ix < this.breakpoints.length; ix++) {
            const bp = this.breakpoints[ix];
            if (props[bp] !== undefined)
                return props[bp];
        }
    }
}

const getBreakpoint = (theme) => {
    return theme.breakpoints.find(({ mediaQuery }) => {
        const mObj = mediaQuery.match(/^ *@media (\(.+?\)) *$/)
        if (!mObj) return false;
        if (window?.matchMedia(mObj[1]).matches) return true;
    })?.name || "mobile";
}


const BreakpointContext = React.createContext({})

export const BreakpointProvider = ({ children }) => {
    const theme = useTheme();
    const [breakpoint, setBreakpoint] = useState(getBreakpoint(theme));
    const breakpointRef = useRef();
    breakpointRef.current = breakpoint;
    useEffect(() => {
        const handler = () => {
            const breakpoint = getBreakpoint(theme);
            if (breakpointRef.current === breakpoint) return;
            setBreakpoint(breakpoint);
        }
        window?.addEventListener("resize", handler);
        const interval = setInterval(handler, 1000);
        return () => {
            window?.removeEventListener("resize", handler);
            clearInterval(interval);
        }
    }, [theme])
    const breakpointObj = useMemo(() => new Breakpoint(breakpoint, theme), [breakpoint, theme]);

    return <BreakpointContext.Provider value={breakpointObj}>{children}</BreakpointContext.Provider>
}
/**
 * 
 * @returns {Breakpoint}
 */
export const useBreakpoint = () => {
    return useContext(BreakpointContext)
}