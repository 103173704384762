import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import isPropValid from '@emotion/is-prop-valid';
import LinkStyle from './Link.style';

const showDebugTags = process.env.REACT_APP_DEBUG === '1';

// eslint-disable-next-line react/display-name
const Component = React.forwardRef(({ as: T = 'section', ...props }, ref) => (
  <T {...props} ref={ref} />
));

export default styled(Component, {
  shouldForwardProp: (prop) =>
    isPropValid(prop) &&
    prop !== 'color' &&
    prop !== 'section' &&
    prop !== 'title' &&
    prop !== 'content' &&
    prop !== 'name' &&
    prop !== 'border' &&
    prop !== 'borderRadius' &&
    prop !== 'loading',
})`
  position: relative;
  --default-pt: ${({ divider }) => (divider?.indexOf('t') >= 0 ? '30px' : 0)};
  --default-pb: ${({ divider }) => (divider?.indexOf('b') >= 0 ? '30px' : 0)};
  --default-px: 15px;
  --default-my: 30px;
  --default-mx: 15px;

  ${({ theme }) => theme.media.desktopSmall} {
    --default-pt: ${({ divider }) => (divider?.indexOf('t') >= 0 ? '60px' : 0)};
    --default-pb: ${({ divider }) => (divider?.indexOf('b') >= 0 ? '60px' : 0)};
    --default-my: 60px;
  }
  --layout-pr: var(--pr);
  --layout-pl: var(--pl);
  --layout-pt: var(--pt);
  --layout-pb: var(--pb);
  ${({ root = false }) =>
    root &&
    css`
      --layout-extra-pl: 0px;
      --layout-extra-pr: 0px;
    `}
  ${({ theme, layout = true, plain = false }) =>
    layout && !plain && theme.layout.container};

  ${({
    pt = 'var(--default-pt)',
    pl = 'var(--default-px)',
    pb = 'var(--default-pb)',
    pr = 'var(--default-px)',
    plain = false,
  }) => css`
    --pt: ${plain ? '0' : pt};
    --pl: ${plain ? '0' : pl};
    --pb: ${plain ? '0' : pb};
    --pr: ${plain ? '0' : pr};
  `};
  ${({ xPaddingAsMargin }) =>
    xPaddingAsMargin
      ? css`
          padding-top: var(--layout-pt);
          padding-bottom: var(--layout-pb);
          margin-left: var(--layout-pl);
          margin-right: var(--layout-pr);
          padding-left: 0;
          padding-right: 0;
        `
      : css`
          padding-top: var(--layout-pt);
          padding-bottom: var(--layout-pb);
          padding-left: var(--layout-pl);
          padding-right: var(--layout-pr);
        `}
  ${({
    plain = false,
    yMarginOutside = true,
    mt = 'var(--default-my)',
    mb = 'var(--default-my)',
  }) =>
    yMarginOutside &&
    css`
      --mt: ${plain ? '0' : mt};
      --mb: ${plain ? '0' : mb};
      margin-top: var(--mt);
      margin-bottom: var(--mb);
    `}
  ${({ flex = false, col = false, reverse = false, wrap = false }) =>
    flex &&
    css`
      display: flex;
      flex-direction: ${reverse ? 'reverse-' : ''} ${col ? 'column' : 'row'};
      flex-wrap: ${wrap ? 'wrap' : 'nowrap'};
    `}

  ${({ grid = false, columns = '1fr' }) =>
    grid &&
    css`
      display: grid;
      grid-template-columns: ${columns};
    `}

  ${({ gap }) =>
    !!gap &&
    css`
      gap: ${gap};
    `}

  ${({ color = 'white', theme, plain }) =>
    // Color defaults to white
    !plain &&
    css`
      background-color: ${theme.colors[color]};
      color: ${theme.colors[theme.complementaryColors[color]]};

      ${LinkStyle} {
        color: ${theme.colors[theme.complementaryColors[color]]};
      }
    `}
  ${({ border = null, borderRadius = null, plain, theme }) =>
    !plain &&
    border &&
    css`
      border: solid thin ${theme.colors[border]};
      border-radius: ${borderRadius ? borderRadius : 'unset'};
    `}
  .container-header {
    position: relative;
    margin-bottom: 32px;
    ${({ border = null, theme }) =>
      !!border &&
      css`
        &:after {
          content: '';
          position: absolute;
          left: calc(0px - var(--layout-pl));
          right: calc(0px - var(--layout-pr));
          bottom: -16px;
          border-bottom: solid thin ${theme.colors[border]};
        }
      `}
  }
  ${({ wlModule, theme }) =>
    showDebugTags &&
    wlModule &&
    css`
      min-height: 10px;
      &::before {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        content: '${wlModule}';
        font-size: 10px;
        line-height: 10px;
        border: solid thin ${theme.colors.neutralGray};
        border-radius: 3px;
        background-color: ${theme.colors.whiteSmoke};
        color: ${theme.colors.blueCharcoal};
        padding: 1px;
        z-index: 1;
      }
    `}

  ${({ theme, wlLayout, layoutColumn = 'primary' }) => {
    return (
      wlLayout &&
      wlLayout !== 'plain' &&
      css`
        ${theme.media.desktopSmall} {
          & > .layout-box {
            position: relative;
            ${theme.layout.box[wlLayout]?.[layoutColumn]};
          }
        }
      `
    );
  }}
`;
