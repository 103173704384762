import React from "react";
import * as ReactDOM from 'react-dom';
import { App, getTarget, prepareRender } from "./reactModules";

const renderClient = () => {
    const renderStrategy = document.documentElement.getAttribute("data-render-strategy") || "render"
    if (renderStrategy === "hydrate") {
        const target = getTarget(document);
        const data = JSON.parse(target.getAttribute("data-wl-data"))
        target.removeAttribute("data-wl-data")
        ReactDOM.hydrate(<App
            modules={data} flatpack="initial" />, target)
    } else {
        const { target, data } = prepareRender(document);
        if (!target) return;
        ReactDOM.render(<App
            modules={data} />, target);
    }
}

export default renderClient;