import React from 'react';
import { Global, css, ThemeProvider, CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { mix } from 'polished';

const remSize = 15;
export const pxToRem = (px) => `${px / remSize}rem`;

const cache = createCache({
  key: 'css',
  container: document?.head,
  prepend: true,
  speedy: process.env.REACT_APP_DEBUG !== '1', //NOTE: speedy should be true for production
});

const breakpoints = [
  {
    name: 'desktopBigger',
    mediaQuery: '@media (min-width: 1980px)',
  },
  {
    name: 'desktopBig',
    mediaQuery: '@media (min-width: 1600px)',
  },
  {
    name: 'desktop',
    mediaQuery: '@media (min-width: 1281px)',
  },
  {
    name: 'desktopSmall',
    mediaQuery: '@media (min-width: 1025px)',
  },
  {
    name: 'laptop',
    mediaQuery: '@media (min-width: 769px)',
  },
  {
    name: 'tablet',
    mediaQuery: '@media (min-width: 501px)',
  },
];

const media = {
  ...Object.fromEntries(breakpoints.map((b) => [b.name, b.mediaQuery])),
  hover: '@media (any-hover: hover)',
  noHover: '@media (any-hover: none)',
};

const energyColors = {
  A: '#35A752',
  B: '#4DB848',
  C: '#BFD631',
  D: '#FEF101',
  E: '#FAB813',
  F: '#F37021',
  G: '#ED1C24',
};

const selectNeutrals = {
  neutral0: 'hsl(0, 0%, 100%)',
  neutral5: 'hsl(0, 0%, 95%)',
  neutral10: 'hsl(0, 0%, 90%)',
  neutral20: 'hsl(0, 0%, 80%)',
  neutral30: 'hsl(0, 0%, 70%)',
  neutral40: 'hsl(0, 0%, 60%)',
  neutral50: 'hsl(0, 0%, 50%)',
  neutral60: 'hsl(0, 0%, 40%)',
  neutral70: 'hsl(0, 0%, 30%)',
  neutral80: 'hsl(0, 0%, 20%)',
  neutral90: 'hsl(0, 0%, 10%)',
};

export const colors = {
  dimGray: '#707070',
  trout: '#495057',
  pacificBlue: '#009EE3',
  bahamaBlue: '#1E4275',
  bahamaBlueLight: mix(0.6)('#1E4275')('#FFFFFF'),
  whiteSmoke: '#F0F0F0',
  white: '#FFFFFF',
  emerald: '#5CC563',
  quartz: '#D7E2F3',
  aliceBlue: '#F1F8FE',
  blueCharcoal: '#25292D',
  neutralGray: '#E5E5E5',
  skyBlue: '#72C5E9',
  error: '#D50000',
  transparent: 'transparent',
  ...energyColors,
  selectNeutrals
};

const fontStyles = (family, set) => {
  return Object.fromEntries(
    Object.entries(set).map(([name, props]) => [
      name,
      fontStyle(family, ...props),
    ])
  );
};

const fontStyle = (family, weight, style) => ({
  fontFamily: family,
  fontWeight: weight,
  fontStyle: style,
});

const columns = (columns, base = '100%', gap = '30px') =>
  `calc((${base} + ${gap}) / 12 * ${columns} - ${gap})`;

const weights = {
  light: 300,
  regular: 400,
  semibold: 600,
  bold: 700,
  extrabold: 800,
};

const fonts = {
  openSans: {
    ...fontStyles('open-sans, sans-serif', {
      light: [weights.light, 'normal'],
      regular: [weights.regular, 'normal'],
      semibold: [weights.semibold, 'normal'],
      bold: [weights.bold, 'normal'],
      extrabold: [weights.extrabold, 'normal'],
      'light-italic': [weights.light, 'italic'],
      italic: [weights.regular, 'italic'],
      'semibold-italic': [weights.semibold, 'italic'],
      'bold-italic': [weights.bold, 'italic'],
      'extrabold-italic': [weights.extrabold, 'italic'],
    }),
    ...fontStyles('open-sans-condensed, sans-serif', {
      'condensed-light': [300, 'normal'],
      'condensed-light-italic': [300, 'italic'],
      'condensed-bold': [700, 'normal'],
    }),
  },
  september: fontStyles('september, sans-serif', {
    medium: [500, 'normal'],
    bold: [700, 'normal'],
    heavy: [900, 'normal'],
    'medium-italic': [500, 'italic'],
    'bold-italic': [700, 'italic'],
    'heavy-italic': [900, 'italic'],
  }),
};

export const Theme = {
  weights,
  colors: colors,
  complementaryColors: {
    dimGray: 'white',
    trout: 'white',
    pacificBlue: 'white',
    bahamaBlue: 'white',
    whiteSmoke: 'blueCharcoal',
    white: 'blueCharcoal',
    emerald: 'white',
    quartz: 'blueCharcoal',
    aliceBlue: 'blueCharcoal',
    blueCharcoal: 'white',
    neutralGray: 'blueCharcoal',
    background: 'blueCharcoal',
  },
  fonts,
  typography: {
    heading1: css`
      ${fonts.september.bold};
      font-size: ${pxToRem(28)};
      line-height: 110%;
      ${media.desktopSmall} {
        font-size: ${pxToRem(36)};
      }
    `,
    heading2: css`
      ${fonts.september.bold};
      font-size: ${pxToRem(22)};
      line-height: 120%;
      ${media.desktopSmall} {
        font-size: ${pxToRem(28)};
      }
    `,
    heading3: css`
      ${fonts.september.bold};
      font-size: ${pxToRem(22)};
      line-height: 120%;
    `,
    heading4: css`
      ${fonts.september.bold};
      font-size: ${pxToRem(20)};
      line-height: 120%;
    `,
    heading5: css`
      ${fonts.september.bold};
      font-size: ${pxToRem(16)};
      line-height: 140%;
      ${media.desktopSmall} {
        font-size: ${pxToRem(18)};
      }
    `,
    heading6: css`
      ${fonts.openSans.semibold};
      font-size: ${pxToRem(15)};
      line-height: 140%;
    `,
    header: css`
      ${fonts.september.bold};
      font-size: ${pxToRem(16)};
      line-height: 120%;
      ${media.desktopSmall} {
        font-size: ${pxToRem(20)};
      }
    `,
    sectionHeading2: css`
      ${fonts.september.bold};
      font-size: ${pxToRem(24)};
      line-height: 120%;
    `,
    septemberRegular: css`
      ${fonts.september.regular};
      font-size: ${pxToRem(16)};
      line-height: 120%;
    `,
    septemberSmall: css`
      ${fonts.september.medium};
      font-size: ${pxToRem(14)};
      line-height: 180%;
    `,
    intro: css`
      ${fonts.openSans.regular};
      font-size: ${pxToRem(15)};
      line-height: 180%;
    `,
    body: css`
      ${fonts.openSans.regular};
      font-size: ${remSize}px;
      line-height: 180%;
    `,
    bodySemibold: css`
      ${fonts.openSans.semibold};
      font-size: ${pxToRem(15)};
      line-height: 180%;
    `,
    control: css`
      ${fonts.openSans.regular};
      font-size: ${pxToRem(15)};
      line-height: 120%;
    `,
    controlStrong: css`
      ${fonts.openSans.semibold};
      font-size: ${pxToRem(15)};
      line-height: 120%;
    `,
    controlSmall: css`
      ${fonts.openSans.regular};
      font-size: ${pxToRem(12)};
      line-height: 120%;
    `,
    controlStrongSmaller: css`
      ${fonts.openSans.semibold};
      font-size: ${pxToRem(12)};
      line-height: 120%;
    `,
    controlStrongSmall: css`
      ${fonts.openSans.semibold};
      font-size: ${pxToRem(12)};
      line-height: 120%;
      ${media.desktopSmall} {
        font-size: ${pxToRem(16)};
      }
    `,
    label: css`
      ${fonts.openSans.semibold};
      font-size: ${pxToRem(15)};
      line-height: 120%;
      color: ${({ theme }) => theme.colors.bahamaBlue};
    `,
    labelInfo: css`
      ${fonts.openSans.regular};
      font-size: ${pxToRem(12)};
      line-height: 180%;
      color: ${({ theme }) => theme.colors.bahamaBlue};
    `,
    formError: css`
      ${fonts.openSans.regular};
      font-size: ${pxToRem(12)};
      line-height: 180%;
      color: ${({theme}) => theme.colors.error};
    `,
    formLegend: css`
      ${fonts.openSans.italic};
      font-size: ${pxToRem(12)};
      line-height: 160%;
      color: ${({ theme }) => theme.colors.trout};
    `,
    underline: css`
      text-decoration: underline;
      text-decoration-thickness: 0.03em;
      text-underline-offset: 0.05em;
    `,
  },
  breakpoints,
  media,
  layout: {
    container: css`
      --layout-max-width: 1280px;
      --pl: 15px;
      --pr: 15px;
      --max-width: calc(var(--layout-max-width) - var(--pl) - var(--pr));
      --ratio: 0.5;
      --layout-pl: calc(
        max(var(--pl), calc((100% - var(--max-width)) * var(--ratio))) +
          var(--layout-extra-pl)
      );
      --layout-pr: calc(
        max(var(--pr), calc((100% - var(--max-width)) * (1 - var(--ratio)))) +
          var(--layout-extra-pr)
      );

      padding-right: var(--layout-pr);
      padding-left: var(--layout-pl);
    `,
    columns,
    box: (() => {
      const columnWidth = 74;
      const gap = 30;

      const sizes = {};

      // fixed
      sizes[2] = `${(columnWidth + gap) * 2 - gap}px`;
      sizes[3] = `${(columnWidth + gap) * 3 - gap}px`;
      sizes[4] = `${(columnWidth + gap) * 4 - gap}px`;

      // fractional
      sizes[5] = `calc((100% + ${gap}px) / 12 * 5 + ${gap}px)`;
      sizes[7] = `calc((100% + ${gap}px) / 12 * 7 + ${gap}px)`;

      // fill
      sizes[8] = `calc(100% - ${gap}px - ${sizes[4]})`;
      sizes[9] = `calc(100% - ${gap}px - ${sizes[3]})`;
      sizes[10] = `calc(100% - ${gap}px - ${sizes[2]})`;

      const result = Object.fromEntries(
        Object.keys(sizes).map((left) => {
          const right = 12 - left;
          return [
            `${left}/${right}`,
            {
              [left > right ? 'primary' : 'secondary']: css`
                margin-left: 0;
                margin-right: auto;
                width: ${sizes[left]};
              `,
              [left > right ? 'secondary' : 'primary']: css`
                margin-left: auto;
                margin-right: 0;
                width: ${sizes[right]};
              `,
            },
          ];
        })
      );
      result['centered'] = {
        primary: css`
          margin-left: auto;
          margin-right: auto;
          max-width: ${(columnWidth + gap) * 8 - gap}px;
          width: 100%;
        `,
        secondary: css`
          display: none;
        `,
      };
      result['centeredbox'] = {
        primary: css`
          margin-left: auto;
          margin-right: auto;
          max-width: ${(columnWidth + gap) * 10 - gap}px;
          width: 100%;
        `,
        secondary: css`
          display: none;
        `,
      };
      result['full'] = {
        primary: css``,
        secondary: css`
          display: none;
        `,
      };
      return result;
    })(),
  },
};

const MyThemeProvider = (p) => {
  return (
    <>
      <Global
        styles={css`
          html {
            ${Theme.typography.body};
            scroll-padding-top: 110px;
            ${media.laptop} {
              scroll-padding-top: 130px;
            }
          }
          :root {
            --top-bar-offset: 0px;
            --top-bar-height: 0px;
            --header-menu-offset: 0px;
            --header-menu-height: 0px;
            --layout-extra-pl: 0px;
            --layout-extra-pr: 0px;
          }
          body {
            visibility: visible !important;
            color: ${Theme.colors[Theme.complementaryColors.background]};
            --scroll-position: 0;
            &.no-scroll {
              position: fixed;
              inline-size: 100%;
              top: calc(0px - var(--scroll-position));
              overflow-y: scroll;
            }
          }
          a {
            color: inherit;
            text-decoration: inherit;
          }
          [data-name='layout-break'] {
            transition: margin-bottom 100ms linear;
          }
        `}
      />
      <CacheProvider value={cache}>
        <ThemeProvider
          theme={Theme}
          {...p}
        />
      </CacheProvider>
    </>
  );
};

export default MyThemeProvider;
