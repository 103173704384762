import aa from 'search-insights';
import { v4 as uuidv4 } from 'uuid';
import { indices } from '../helpers/indices';

const debug = process.env.REACT_APP_DEBUG === '1';

const userId = (() => {
    let id = null;
    try {
        id = localStorage.getItem("userId");
    } catch (e) {
        // Ignore
    }
    if (!id) {
        id = uuidv4();
        try {
            localStorage.setItem("userId", id);
        } catch (e) {
            // Ignore
        }
    }
    if (debug) return `debug-${id}`;
    return id;
})()


aa('init', {
    appId: process.env.REACT_ALGOLIA_APPLICATION_ID,
    apiKey: process.env.REACT_ALGOLIA_SEARCH_API_KEY
})
aa('setUserToken', userId);
const sendConversionEvent = ({ objectID, eventName, queryID = null }) => {
    try {
        const event = queryID ? "convertedObjectIDsAfterSearch" : "convertedObjectIDs"
        aa(event, {
            queryID: queryID || undefined,
            index: indices.vehicles,
            eventName,
            objectIDs: [objectID]
        })
    } catch (e) {
        if (debug) console.error(e)
    }
}
export const useAnalytics = () => ({ aa, sendConversionEvent });

export { aa }
