import { labelGetter } from '../../helpers/labelGetter';
import { useListFilter } from '../../hooks/useListFilter';

export const namedItems = [
  { value: 'passenger', label: 'Personenauto', slug: 'p' },
  { value: 'commercial', label: 'Bedrijfsauto', slug: 'b' },
];

export const carTypeLabel = labelGetter(namedItems);

export const useCarType = useListFilter({
  namedItems,
  attribute: 'type',
  urlParam: 'type',
  label: 'Type auto',
});
