/**
 * This file contains a helper function and related utilities for managing shared resources.
 * The shared resources are stored in a cache object and can be cleaned up based on their size and timestamp.
 * The cache uses a storage provider to store and retrieve the resources.
 * The resources can be fetched using a fetcher function and can be optionally refreshed after a certain time interval.
 * The cache supports shared resources that allow requesting one item at a time or multiple items at a time.
 * 
 * @module sharedResource
 */
import { isPreview } from "./hsPreview"

// Maximum storage size for the cache in bytes (750 KB)
const storageSize = 750e3; // 750kb

// Cache object to store shared resources
export const cache = {}

// Make the cache accessible globally for debugging purposes
window.sharedResources = cache;

/**
 * Cleans up the storage by removing items based on their size and timestamp.
 * @param {Storage} storage - The storage object to be cleaned up.
 * @returns {Promise<void>} - A promise that resolves when the cleanup is complete.
 */
const storageCleanup = async (storage) => {
    if (!storage) return;
    // Resolve storage promise if necessary
    storage = await storage;
    try {
        const items = []
        const keys = await storage.keys();
        for (const key of keys) {
            const item = await storage.getItem(key);
            let timestamp = null;
            try {
                // Parse the timestamp from the stored item
                timestamp = JSON.parse(item).timestamp;
            } catch (e) {
                // Ignore parse errors
            }
            items.push({
                key,
                size: item.length,
                timestamp
            });
        }
        // Sort items by their timestamp, prioritizing non-adDetail items
        items.sort((a, b) => {
            const aIsAdDetail = a.key.startsWith("sharedResource/adDetail/");
            const bIsAdDetail = b.key.startsWith("sharedResource/adDetail/");
            if (aIsAdDetail && !bIsAdDetail) return 1;
            if (!aIsAdDetail && bIsAdDetail) return -1;
            return b.timestamp - a.timestamp
        });
        const remove = []
        let sizeLeft = storageSize;
        for (const item of items) {
            sizeLeft -= item.size;
            if (sizeLeft < 0) remove.push(item.key);
        }
        // Remove items that exceed the storage size limit
        for (const key of remove) {
            storage.removeItem(key);
        }
    } catch (e) {
        // Ignore errors
    }
}

// Provides a storage interface using the Cache API
const cacheStorageProvider = (async () => {
    const storage = await window.caches.open("sharedResourceCache");
    window.sharedResourceCache = storage;
    const rootPath = window.location.protocol + "//" + window.location.host + "/_localcache/";
    return {
        removeItem: async (key) => {
            return await storage.delete(new URL(rootPath + key))
        },
        getItem: async (key) => {
            return await storage.match(new URL(rootPath + key)).then(rsp => {
                if (rsp) return rsp.text();
                return null;
            })
        },
        setItem: (key, value) => {
            return storage.put(new URL(rootPath + key), new Response(value, {
                headers: {
                    "Content-Type": "application/json",
                    "Content-Length": value.length + ""
                }
            }));
        },
        keys: async () => {
            return (await storage.keys()).map(r => r.url.slice(rootPath.length));
        }
    }
})()

// Periodically clean up storage every 15 seconds
try {
    setInterval(() => storageCleanup(cacheStorageProvider), 15000);
} catch (e) {
    // Ignore errors
}

/**
 * Stores an item in the provided storage.
 * @param {Storage} storage - The storage object.
 * @param {string} key - The key under which the item is stored.
 * @param {any} value - The value to store.
 */
const storagePut = async (storage, key, value) => {
    if (!storage) return;
    // Resolve storage promise if necessary
    storage = await storage;
    try {
        storage.setItem(key, JSON.stringify({ value, timestamp: Date.now() }))
    } catch (e) {
        // Ignore, just don't cache it
    }
}

/**
 * Retrieves an item from the provided storage, or fetches it using the getter if not present or expired.
 * @param {Storage} storage - The storage object.
 * @param {string} key - The key under which the item is stored.
 * @param {Function} getter - The function to fetch the item if not cached.
 * @param {number} validFor - The validity duration of the cached item in milliseconds.
 * @param {number} refreshAfter - The duration after which the item should be refreshed in milliseconds.
 * @returns {Promise<any>} - A promise that resolves to the cached or fetched item.
 */
const storageGet = async (storage, key, getter, validFor, refreshAfter) => {
    let cached = null
    // Resolve storage promise if necessary
    storage = await storage;
    if (storage)
        try {
            cached = await storage.getItem(key);
        } catch (e) {
            // Ignore
        }
    const storer = (value) => {
        storagePut(storage, key, value);
        return value
    }
    if (!cached) {
        return getter().then(storer);
    }
    const { value, timestamp } = JSON.parse(cached);
    const validUntil = timestamp + validFor;
    if (Date.now() > validUntil) {
        return getter().then(storer);
    }

    const refreshAt = timestamp + refreshAfter;
    if (Date.now() > refreshAt) {
        getter().then(storer);
    }

    return Promise.resolve(value);
}

/**
 * Creates a shared resource with caching and optional refreshing.
 * @param {string} name - The name of the shared resource.
 * @param {Function} fetcher - The function to fetch the resource.
 * @param {string|Function} [defaultId=null] - The default ID for the resource.
 * @param {Object} [options={}] - Additional options for the resource.
 * @param {number} [options.refreshAfter=null] - The duration after which the resource should be refreshed in milliseconds.
 * @param {number} [options.validFor=null] - The validity duration of the cached resource in milliseconds.
 * @param {boolean} [options.session=true] - Whether to use session storage.
 * @param {any} [options.preview=null] - The preview data to use if in preview mode.
 * @returns {Function} - The function to get the shared resource.
 */
export const createSharedResource = (name, fetcher, defaultId = null, { refreshAfter = null, validFor = null, session = true, preview = null } = {}) => {
    // Return preview data if in preview mode
    if (isPreview && preview) {
        const rsp = () => Promise.resolve(preview)
        rsp.put = () => { }
        return rsp;
    }
    if (!cache[name]) cache[name] = {}
    let storage = null;
    try {
        storage = cacheStorageProvider // session ? sessionStorage : localStorage;
    } catch (e) {
        // Ignore errors
    }
    const key = (id) => `sharedResource/${name}/${id}`
    const put = (id, value) => {
        cache[name][id] = Promise.resolve(value);
        storagePut(storage, key(id), value);
    }
    const rsp = async (id = null) => {
        if (id === null && defaultId) id = defaultId();
        if (!id) {
            if (typeof defaultId === 'string') {
                id = defaultId || "__DEFAULT";
            } else if (typeof defaultId === 'function') {
                id = defaultId() || "__DEFAULT";
            } else {
                id = "__DEFAULT";
            }
        }
        if (!cache[name][id]) {
            if (validFor) {
                if (!refreshAfter) refreshAfter = validFor / 2;
                cache[name][id] = await storageGet(
                    storage,
                    key(id),
                    () => fetcher(id), validFor, refreshAfter
                );
            } else {
                cache[name][id] = fetcher(id);
            }
        }
        return cache[name][id]
    }
    rsp.put = put;
    return rsp;
}

/**
 * Creates a shared resource for multiple IDs, fetching all at once if any are missing.
 * @param {string} name - The name of the shared resource.
 * @param {Function} fetcher - The function to fetch the resource.
 * @returns {Function} - The function to get the shared resource for multiple IDs.
 */
export const createMultiSharedResource = (name, fetcher) => async (...ids) => {
    if (!cache[name]) cache[name] = {}
    const toFetch = []
    for (const id of ids) {
        if (!cache[name][id]) {
            toFetch.push(id)
        }
    }
    const promise = fetcher(...toFetch)
    for (const id of toFetch) {
        cache[name][id] = promise.then(res => res[id])
    }
    return Object.fromEntries(ids.map(id => [id, cache[name][id]]))
}
