import styled from '@emotion/styled';

export default styled.a`
  color: ${({ theme }) => theme.colors.pacificBlue};
  ${({ theme }) => theme.typography.underline};
  transition: color 150ms ease-out;
  cursor: pointer;

  &:active {
    color: ${({ theme }) => theme.colors.bahamaBlue};
  }

  ${({ theme }) => theme.media.hover} {
    &:hover {
      color: ${({ theme }) => theme.colors.bahamaBlue};
    }
  }
`;
