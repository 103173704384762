export default (key, value, openInNewTab = false) => {
    //Open at page index number
    if(key === 'p') return true;

    //Referer for dealer id
    if(key === 'referer') return true;

    //Do we need to open in a new tab
    if(key === 'open_in_new_tab') return true;

    if(!openInNewTab) {
        //Is lease overview in iframe
        if(key === 'is_embedded') return true;

        //Overview style options for iframe
        if(key === 'primary_color') return true;
        if(key === 'secondary_color') return true;
        if(key === 'text_color') return true;
        if(key === 'buttons_color') return true;
        if(key === 'background_color') return true;
        if(key === 'secondary_background_color') return true;
    }

    //Remove all forbidden keys
    return false;
}

