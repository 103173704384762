import React, {useEffect, useMemo, useState, useRef} from "react";
import * as ReactDOM from 'react-dom';
import ErrorBoundary from "../../components/global/ErrorBoundary";

import ThemeProvider from '../../theme/Theme';
import { BreakpointProvider } from "../providers/breakpointProvider";
import GlobalStyles from "../../components/global/GlobalStyle";

/**
 * A module that can be rendered by the application.
 * @typedef {({
 *   default : React.FunctionComponent,
 *   getServerSideProps?: (fields: {[k:string]:string}) => Promise<any>
 * })} AppModule
 */

/**
 * A map of available modules
 * @type {{[key: string]: AppModule}}
 */
const availableModules = {
}

/**
 * Register a module to be used by the application
 * @param p {{[key: string]: AppModule}}
 */
export const registerModule = (p) => {
    Object.assign(availableModules, p)
    for (const name of Object.keys(p)) {
        // trigger an event to notify InnerModule that the module is now available
        document.dispatchEvent(new CustomEvent("moduleRegistered", {detail: {name}}))
    }
}

const InnerModule = ({wlModule, props}) => {
    // if the module is already loaded, we can render it immediately
    const [{Component}, setModuleState] = useState({
        Component: availableModules[wlModule]?.default
    });

    // if the module is not loaded yet, we listen for the moduleRegistered event
    useEffect(() => {
        if (Component) return;
        const handler = (e) => {
            if (e.detail.name !== wlModule) return;
            setModuleState({Component: availableModules[wlModule]?.default});
        }
        document.addEventListener("moduleRegistered", handler)
        return () => document.removeEventListener("moduleRegistered", handler)

    }, [wlModule, Component])

    // if the module is still not loaded, we render nothing
    if (!Component) return <></>

    // if the module is loaded, we render it
    return <Component {...props} />
}

const Module = ({
    elementId: targetId,
    element: targetElement,
    flatpack,
    props: {
        wlModule,
        fields,
        serverProps: _serverProps = null,
        serverPropsPromise = null,
        ...rest
    }
}) => {
    const [serverProps, setServerProps] = useState(_serverProps)

    // flatpack means we render the element without using a portal, because SSR does not support portals, so we don't have a target
    const target = useMemo(() => flatpack ? null : (targetElement || document.getElementById(targetId)), [targetId, targetElement, flatpack])

    useMemo(() => {
        // empty the element before rendering
        if (target) target.innerHTML = ''
    }, [target]);

    useMemo(() => {
        if (serverProps === null && serverPropsPromise) {
            serverPropsPromise.then(res => {
                setServerProps(res)
            })
        }
    }, []);
    const error = serverProps?.error
    const element = <ErrorBoundary error={error}>
        <InnerModule wlModule={wlModule} props={{
            wlModule, flatpack,
            ...fields,
            ...serverProps?.props,
            ...rest,
        }} />
    </ErrorBoundary>
    if (!target) {
        return element;
    }
    return ReactDOM.createPortal(element, target)
}

export const App = ({ modules, flatpack: _flatpack, ...p }) => {
    // flatpack means we render the element without using a portal, because SSR does not support portals
    // when we hydrate the app on the client, we first must render it in flatpack mode, so the expected HTML
    // matches what was generated by the server. We then use useEffect to disable flatpack mode on the next tick
    // and move the elements to the correct location in the DOM
    const [flatpack, setFlatpack] = useState(!!_flatpack)
    useEffect(() => {
        if (_flatpack === "initial") setFlatpack(false)
    })


    // Note that all modules are rendered through portals, 
    // so the actual #app element remains empty unless we're flatpacking in SSR mode (see above)
    // except for fixed components, which are actually rendered there
    return (
        <ThemeProvider>
            <BreakpointProvider>
                <GlobalStyles />

                {modules.map((i, ix) => (
                    <Module key={ix} {...p} {...i} flatpack={flatpack} />
                ))}
            </BreakpointProvider>
        </ThemeProvider>
    );
}

export const getTarget = (document) => {
    return document.querySelector("#app");
}

/**
 * Like Object.fromEntries, but when a key exists multiple times, returns it as an array
 * @param {any[]} entries 
 * @returns {object}
 */
const fromEntries = (entries) => {
    const target = {};
    const forcedArrays = entries
        .filter(([_, v]) => v.wlType?.toLowerCase() === 'array')
        .map(i => i[0])
        .reduce((p, k) => {
            p[k] = true
            return p
        }, {})
    const keyCounts = entries.map(i => i[0]).reduce((p, k) => {
        if (p[k] === undefined) p[k] = 0;
        p[k]++
        return p
    }, {})
    for (const [key, value] of entries) {
        if (keyCounts[key] > 1 || forcedArrays[key]) {
            if (target[key] === undefined) target[key] = [];
            target[key].push(value)
        } else {
            target[key] = value
        }
    }
    return target
}

const parseFields = (element) => {
    const fields = element.querySelectorAll("[data-wl-field]");
    if (!fields.length) return null;
    return fromEntries(
        Array.from(element.querySelectorAll("[data-wl-field]"))
            .filter(e => {
                // only look at the first level of fields 
                // (not necessarily directly under the module/field though)
                const parentField = e.parentElement.closest("[data-wl-field]");
                return parentField === null || parentField === element
            })
            .map(( /** @type {HTMLElement} */ e) => {

                const { wlField, ...rest } = e.dataset;

                const attrs = {}

                if (e.tagName === "A") {
                    attrs.rel = e.rel;
                    attrs.nofollow = e.nofollow;
                    attrs.target = e.target;
                    attrs.href = e.href;
                }
                if (e.tagName === "IMG") {
                    attrs.src = e.src;
                    attrs.alt = e.alt;
                    attrs.width = e.width;
                    attrs.height = e.height;
                }
                if (e.tagName === "PICTURE") {
                    const source = e.querySelector("source")
                    const img = e.querySelector("img")
                    Object.assign(attrs, {
                        images: Object.fromEntries(
                            source.getAttribute("srcSet")
                                .split(/, */)
                                .map(i => i.split(/ +/).reverse())),
                        width: img.getAttribute("width"),
                        height: img.getAttribute("height"),
                        src: img.getAttribute("src"),
                        alt: img.getAttribute("alt"),
                    })
                }

                const subFields = parseFields(e);

                const props = { ...attrs, ...rest, ...subFields };
                if (!subFields) {
                    const content = e.innerHTML;
                    if (content.trim().length > 0) {
                        props.content = content;
                    }
                }
                return [wlField.replace(/-(.)/g, (_, m) => m.toUpperCase()), props]
            }))
}

/**
 * Wait for a module to be registered
 * @param moduleName the name of the module to wait for
 * @returns {Promise<AppModule>} a promise that resolves to the module
 */
const waitForModule = (moduleName)=>{
    const module = availableModules[moduleName];
    if(module) {
        return Promise.resolve(module);
    }
    return new Promise((resolve)=>{
        const handler = (e) => {
            if (e.detail.name !== moduleName) return;
            document.removeEventListener("moduleRegistered", handler)
            resolve(availableModules[moduleName])
        }
        document.addEventListener("moduleRegistered", handler)
    })
}

const getServerSideProps = async (module, props) => {
    const loadedModule=(await module);
    if (loadedModule?.getServerSideProps) {
        const _promise = loadedModule.getServerSideProps(props.fields)
        const promise = (
            // If the method is not async, wrap it in a resolved promise
            _promise.then
                ? _promise
                : Promise.resolve(_promise)
        ).then((res) => {
            props.serverProps = res
            return props.serverProps;
        }).catch((error) => {
            props.serverProps = { error }
            return props.serverProps;
        })
        return await promise
    }
}

/**
 * Prepare rendering of the app
 * @param {Document} document the document-type object for the DOM (either real or virtual)
 * @returns {{
 *  target: HTMLElement
 *  data: {
 *    props:{
 *      wlModule: string,
 *      wlLayout: string,
 *      serverProps: any,
 *      serverPropsPromise: Promise,
 *      fields: {[k:string]:{[k:string]:string}},
 *      [k:string]:string
 *    }
 *  }[],
 *  promise: Promise<PromiseSettledResult<any>>
 * }}
 */
export const prepareRender = (document) => {

    const app = getTarget(document);
    const modules = document.querySelector("#modules");
    if (!app || !modules) return;
    const elements = modules.querySelectorAll("[data-wl-module]");
    const serverProps = [];
    const data = Array.from(elements).map(element => {
        const props = { ...element.dataset };
        props.fields = parseFields(element) || {}
        const module = waitForModule(props.wlModule);
        const promise = getServerSideProps(module, props)
        serverProps.push(promise);
        props.serverPropsPromise = promise;
        return { element, elementId: element.id, props }
    })
    return {
        target: app,
        data,
        promise: Promise.allSettled(serverProps)
    }
}
