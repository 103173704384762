import { createSharedResource } from "./sharedResource";


const fetchHubDbModel = async (id) => {
    const tableId = document.getElementById('hubdb-models-table').content
    const portalId = document.getElementById('hubdb-portal-id').content

    const rsp = await window.fetch(
        `https://api.hubapi.com/cms/v3/hubdb/tables/${tableId}/rows?` +
        new URLSearchParams({
            portalId,
            autokan_id: id,
            limit: 1
        })
    );
    const data = await rsp.json();
    if(!data.results[0]) return undefined;
    return {
        id: data.results[0]?.id,
        ...data.results[0]?.values
    };
}

export const getHubDbModel = createSharedResource('hubdbModel', fetchHubDbModel);
