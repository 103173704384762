import { locationToStr, parseUrl, updateUrl } from "../helpers/url"
import paramWhitelistFilter from "../helpers/paramWhitelistFilter"
/**
 * Class representing a State Mapping utility.
 * 
 * This utility class provides methods to map application states to route states and vice versa.
 * It allows adding and removing handlers for state transformations and enables/disables the mapping functionality.
 * 
 * @property {boolean} enabled - Indicates whether the state mapping is enabled or disabled.
 * 
 * @method disable - Disables the state mapping functionality.
 * @method enable - Enables the state mapping functionality.
 * @method addStateToRouteHandler - Adds a handler function for transforming application state to route state.
 * @method removeStateToRouteHandler - Removes a previously added handler for state-to-route transformation.
 * @method addRouteToStateHandler - Adds a handler function for transforming route state to application state.
 * @method removeRouteToStateHandler - Removes a previously added handler for route-to-state transformation.
 * @method stateToRoute - Transforms application state to route state using registered handlers.
 * 
 * @param {Object} uiState - The current application state to be transformed into a route state.
 * @returns {Object} The transformed route state.
 * 
 * @method routeToState - Transforms a given route state into an application state using registered handlers.
 * 
 * @param {Object} routeState - The route state to be transformed into an application state.
 * @returns {Object} The transformed application state, specifically containing a `vehicles` object.
 * 
 * @example
 * const routeState = { /* route state data * / };
 * const appState = routeToState(routeState);
 * console.log(appState); // { vehicles: { /* transformed state * / } }
 **/
class StateMapping {
    constructor() {
        this.stateToRoute = this.stateToRoute.bind(this)
        this.routeToState = this.routeToState.bind(this)
        this.stateToRouteHandlers = []
        this.routeToStateHandlers = []
        this.enabled = true;
    }
    disable() {
        this.enabled = false;
    }
    enable() {
        this.enabled = true;
    }
    get available() {

        return parseUrl(window.location)?.type === "adListing"
    }
    addStateToRouteHandler(handler) {
        this.stateToRouteHandlers.push(handler)
    }
    removeStateToRouteHandler(handler) {
        const ix = this.stateToRouteHandlers.indexOf(handler);
        if (ix < 0) return;
        this.stateToRouteHandlers.splice(ix, 1)
    }
    addRouteToStateHandler(handler) {
        this.routeToStateHandlers.push(handler)
    }
    removeRouteToStateHandler(handler) {
        const ix = this.routeToStateHandlers.indexOf(handler);
        if (ix < 0) return;
        this.routeToStateHandlers.splice(ix, 1)
    }
    stateToRoute(uiState) {
        const result = {}
        if (!this.enabled || !this.available) return result;
        const state = uiState?.vehicles || {}
        //console.log("stateToRouteHandlers", this.stateToRouteHandlers.length)
        this.stateToRouteHandlers.forEach(handler => handler(state, result))
        //console.log("stateToRoute", state, result)
        return result
    }
    
    /**
     * Transforms a given route state into an application state.
     *
     * This method processes the provided `routeState` using registered handlers
     * to generate a corresponding application state. It returns an object
     * representing the state of vehicles.
     *
     * @param {Object} routeState - The state derived from the route, which needs to be transformed.
     * @returns {Object} The transformed application state, specifically containing a `vehicles` object.
     * 
     * @example
     * const routeState = { /* route state data * / };
     * const appState = routeToState(routeState);
     * console.log(appState); // { vehicles: { /* transformed state * / } }
     */
    routeToState(routeState) {
        const state = {}
        if (!this.enabled || !this.available) return state;
        //console.log("routeToStateHandlers", this.routeToStateHandlers.length)
        this.routeToStateHandlers.forEach(handler => handler(routeState, state))
        const result = {
            vehicles: state
        }
        //console.log("routeToState", routeState, result)
        return result
    }
}
class Router {
    constructor() {
        this.callback = null;
        this.lastLocation = null;
        this.firstWrite = true;
        this.restart();
    }
    stop() {
        clearInterval(this.interval)
    }
    restart() {
        this.interval = setInterval(() => this.check(), 100)
    }
    check() {
        const currentLocation = locationToStr()
        if (currentLocation === this.lastLocation) return;
        this.lastLocation = currentLocation;
        this.updated();
    }
    onUpdate(callback) {
        this.callback = callback;
    }
    updated() {
        this.callback?.(this.read());
    }
    read() {
        return parseUrl();
    }
    write(route) {
        this.stop();
        const preserveUtm = this.firstWrite
        if (!preserveUtm) route = {
            ...route, searchParamFilter: paramWhitelistFilter,
        }
        this.lastLocation = updateUrl(route, { action: "push" })
        this.firstWrite = false
        this.restart();
    }
    createURL(state) { }
    dispose() { }

}

class Routing {
    constructor() {
        this.stateMapping = new StateMapping();
        this.router = new Router();
    }
    add({ stateToRoute, routeToState }) {
        this.stateMapping.addStateToRouteHandler(stateToRoute)
        this.stateMapping.addRouteToStateHandler(routeToState)
    }
    remove({ stateToRoute, routeToState }) {
        this.stateMapping.removeStateToRouteHandler(stateToRoute)
        this.stateMapping.removeRouteToStateHandler(routeToState)
    }
    disable() {
        this.stateMapping.disable()
    }
    enable() {
        this.stateMapping.enable()
    }
}

export const routing = new Routing();

