import React, { useEffect } from 'react';
import { useTheme, Global, css } from '@emotion/react';

import hasCustomTheme from '../../app/helpers/hasCustomTheme';

const GlobalStyles = () => {
  hasCustomTheme();
  const theme = useTheme();

  return (
    <Global
      styles={css`
        html {
          background: ${theme.colors.white};
        }
      `}
    />
  );
};

export default GlobalStyles;
