import { ensureUrl, updateUrl } from "./url";


const getAdId = () => {
    const { type, adId } = ensureUrl()
    if (type !== "ad") {
        updateUrl({
            "type": 404
        }, { action: "redirect" });
        return
    }
    return adId;
}

export default getAdId