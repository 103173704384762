import React, { useContext, useLayoutEffect, useState } from "react";
import algoliasearch from "algoliasearch/lite";
import { Configure, InstantSearch, useInstantSearch, useSearchBox } from "react-instantsearch-hooks-web";
import { createInsightsMiddleware } from 'instantsearch.js/es/middlewares';
import { aa } from "../hooks/useAnalytics";
import { routing } from "./algoliaRouting";
import { indices } from "../helpers/indices";
import getAdDetail from "../resources/adDetail";

const cacheAds = (ads) => {
    ads.forEach(i => getAdDetail.put(i.id, i));
}
function InsightsMiddleware() {
    const { use } = useInstantSearch();

    useLayoutEffect(() => {
        const middleware = createInsightsMiddleware({
            insightsClient: aa,
        });

        return use(middleware);
    }, [use]);

    return null;
}

const algoliaSearchClient = algoliasearch(
    process.env.REACT_ALGOLIA_APPLICATION_ID,
    process.env.REACT_ALGOLIA_SEARCH_API_KEY
);
const vehicleModelFormat = /^App\\Models\\Vehicle::\d+$/
export const searchClient = {
    ...algoliaSearchClient,
    async search(requests) {
        const result = await algoliaSearchClient.search(requests);
        const hits = result?.results?.flatMap(i => i?.hits);
        const cars = hits.filter(i => i.objectID?.match(vehicleModelFormat));
        cacheAds(cars);
        return result;
    },
}

// The waiting time after a keypress before a request is initiated
export const rateLimitMs = 250;

// The minimum number of characters to require before a request is made
//export const queryMinChars = 3;

export const queryValid = (query) => true;//(query?.length || 0) >= queryMinChars;

const queryHook = (() => {
    // rate limit queries
    let timer = null;
    return (query, hook) => {
        timer !== null && clearTimeout(timer);
        timer = setTimeout(() => {
            hook(query);
        }, rateLimitMs);
    };
})();


const searchFunction = (helper) => {
    if (!queryValid(helper.state.query)) return;
    //analyticsEvent("query", {
    //  query: helper.state.query,
    //  refinedFacets: helper.state.disjunctiveFacetsRefinements,
    //  resultsPage: helper.state.page,
    //});
    helper.search();
};

const SearchContext = React.createContext({})

export const InnerSearchProvider = ({ children }) => {
    const state = useSearchBox({
        queryHook,
    });
    return <SearchContext.Provider value={state}>{children}</SearchContext.Provider>
}

/**
 * SearchProvider component that sets up the Algolia InstantSearch environment.
 *
 * This component wraps its children with the necessary context providers and configurations
 * for performing search operations using Algolia's InstantSearch library.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to be rendered within the search context.
 *
 * @returns {JSX.Element} The InstantSearch component configured with search client, index, and routing.
 *
 * The component includes:
 * - InsightsMiddleware: Middleware for handling insights with Algolia.
 * - GeoSearchProvider: Provides geographical search context.
 * - GeoSearchFilter: Configures search parameters based on geographical data.
 * - InnerSearchProvider: Provides search box state and context.
 */
export const SearchProvider = ({ children }) => {
    return <InstantSearch
        searchClient={searchClient}
        indexName={indices.vehicles}
        searchFunction={searchFunction}
        routing={routing}
    >
        <InsightsMiddleware />
        <GeoSearchProvider>
            <GeoSearchFilter />
            <InnerSearchProvider>{children}</InnerSearchProvider>
        </GeoSearchProvider>
    </InstantSearch>
}

const GeoSearchContext = React.createContext({})

/**
 * GeoSearchProvider is a React component that provides geographical search context to its children.
 * It manages the state for latitude, longitude, and distance in meters, and supplies these values
 * through a context provider.
 *
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children - The child components that will have access to the geographical search context.
 *
 * @returns {JSX.Element} A context provider component that wraps its children with geographical search state.
 */
const GeoSearchProvider = ({ children }) => {
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const [distanceInM, setDistanceInM] = useState(null);
    return <GeoSearchContext.Provider value={{
        lat, setLat,
        lng, setLng,
        distanceInM, setDistanceInM
    }}>{children}</GeoSearchContext.Provider>
}
export const useGeoSearch = () => useContext(GeoSearchContext);
const GeoSearchFilter = () => {
    const { lat, lng, distanceInM } = useGeoSearch();
    if (lat && lng && distanceInM)
        return <Configure
            aroundLatLng={`${lat}, ${lng}`}
            aroundRadius={`${distanceInM}`}
            /* 
            Disable dynamic re-ranking when geosearch is enabled because it causes 
            a bug where we get responses that do not adhere to the geo filter. 
            https://wirelab.atlassian.net/browse/KRE1618-1317
            */
            enableReRanking={false}
        />
    return null;
}


/**
 * 
 * @returns {Search}
 */
export const useSearch = () => {
    return useContext(SearchContext)
}