import React from 'react';
import StyledContainer from './Container.style';
import PropTypes from 'prop-types';
import { Theme } from '../../theme/Theme';

// eslint-disable-next-line react/display-name
const Container = React.forwardRef(({ wlLayout, children, ...p }, ref) => {
  if (wlLayout && wlLayout !== 'plain') {
    return (
      <StyledContainer wlLayout={wlLayout} {...p} ref={ref}>
        <div className="layout-box">{children}</div>
      </StyledContainer>
    );
  }
  return (
    <StyledContainer {...p} ref={ref}>
      {children}
    </StyledContainer>
  );
});

Container.propTypes = {
  flex: PropTypes.bool,
  dir: PropTypes.bool,
  xPaddingAsMargin: PropTypes.bool,
  gap: PropTypes.string,
  reverse: PropTypes.bool,
  color: PropTypes.oneOf(Object.keys(Theme.colors)),
  wlModule: PropTypes.string,
  border: PropTypes.oneOf(Object.keys(Theme.colors)),
};

export default Container;
