export const GetUtmAndGclidParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsObject = {};

    for (const [key, value] of urlParams.entries()) {
        paramsObject[key] = value;
    }

    return paramsObject;
};

export default GetUtmAndGclidParams;