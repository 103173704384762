/**
 * This is the entrypoint for the client-side app
 */
import { toggleActiveClick } from './modules/toggleActiveClick';
import initReactModules from './modules/reactModules.client';
import disableInputNumberScroll from './modules/disableInputNumberScroll';
import updateVdpCanonicals from './resources/canonicals';
import saveUtmVars from './modules/saveUtmVars';
import { initSentry } from './helpers/sentry';

function docReady(fn) {
  // see if DOM is already available
  if (
    document.readyState === 'complete' ||
    document.readyState === 'interactive'
  ) {
    // call on next available tick
    setTimeout(fn, 1);
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

window.scrollToModule = (
  moduleType,
  index = 0,
  smooth = true,
  offset = 100
) => {
  if (moduleType) {
    const module = document
      .querySelectorAll(`[data-wl-module='${moduleType}']`)
      ?.[index];
    if (!module) return;
    window.scrollTo({
      behavior: smooth ? 'smooth' : 'instant',
      top:
        module.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        offset,
    });
  } else {
    window.scrollTo({
      behavior: smooth ? 'smooth' : 'instant',
      top: 0
    });
  }
};

docReady(() => {
  initSentry();
  toggleActiveClick();
  disableInputNumberScroll();
  initReactModules();
  updateVdpCanonicals();
  saveUtmVars();
});


