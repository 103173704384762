import * as Sentry from "@sentry/browser";

export const initSentry = () => {
    const debug = process.env.REACT_APP_DEBUG === '1';
    const appSentryDsn = process.env.REACT_APP_SENTRY_DSN;
    if (appSentryDsn) {
        Sentry.init({
            dsn: appSentryDsn,

            integrations: [
                Sentry.browserTracingIntegration()
            ],
            debug,
            environment: debug ? 'development' : 'production',
            ignoreErrors: [/^RetryError/],
            denyUrls: [
                // Chrome extensions
                /extensions\//i,
                /^chrome:\/\//i,
                // Facebook flakiness
                /graph\.facebook\.com/i,

                // GTM
                /tracking\.autokan\.nl/i,
                /assets\.exatom\.io/i,
                /autokan\.nl\/hs\/hsstatic\//i,
            ],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 0.00001,

            beforeSend(event) {
                try {
                    const exception = event.exception.values[0];
                    if (exception.stacktrace.frames[0].filename === `<anonymous>`) {
                        return null;
                    }

                    if (
                        exception.type === 'TypeError' &&
                        exception.value === 'load failed'
                    ) {
                        // network or browser error
                        return null;
                    }
                } catch (e) {
                    console.error(e);
                    // no-op
                }

                return event;
            },
        });
        window.SentryImpl = Sentry;
    }
}
