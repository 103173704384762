import queryString from 'query-string';
import slugify from 'slugify';
import { namedItems as carTypeItems } from '../providers/algoliaFilters/carType';
import getAdDetail from '../resources/adDetail';
import { getHubDbBrand } from './brand';
import { isPreview } from './hsPreview';
import { unMemoizedIsEmbedded, unMemoizedOpenAdsInNewTab } from './isEmbedded';
import { getHubDbModel } from './model';

const searchParams = new URLSearchParams(window.location.search || '');
const noredirect = searchParams.has('no-redirect');
const norewrite = searchParams.has('no-rewrite');
const debug = process.env.REACT_APP_DEBUG === '1';
class Location {
  constructor(key) {
    this.key = key;
    try {
      this.value = JSON.parse(window.sessionStorage.getItem(key) || '[]');
    } catch (e) {
      this.value = [];
    }
  }
  update(callback) {
    try {
      this.value = callback(this.value);
      window.sessionStorage.setItem(this.key, JSON.stringify(this.value));
    } catch (e) {
      // noop
    }
  }

  replace(newUrl, type = 'adListing') {
    this.update((value) => {
      value[0] = { type, url: newUrl };
      return value;
    });
    window.history.replaceState({}, '', newUrl);
  }
  push(newUrl, type = 'adListing') {
    this.update((value) => {
      value.splice(0, 0, { type, url: newUrl });
      return value;
    });
    window.history.pushState({}, '', newUrl);
  }
  redirect(newUrl, type = 'adListing') {
    this.update((value) => {
      value.splice(0, 0, { type, url: newUrl });
      return value;
    });

    window.location.replace(newUrl);
  }

  getLatest(type) {
    return this.value.find((i) => i.type === type);
  }

  init() {
    try {
      const urlInfo = parseUrl(window.location);
      let url = window.location.href;
      if (!urlInfo) return;
      if (urlInfo.type === "adListing") {
        if (unMemoizedIsEmbedded() && unMemoizedOpenAdsInNewTab()) {
          url = adListing({ ...urlInfo, searchParamFilter: (k) => k !== "open_in_new_tab" && k !== "is_embedded" && k !== "referer" })
        }
      }
      this.update((value) => {
        value.splice(0, 0, { type: urlInfo.type, url });
        return value;
      });
    } catch (e) {
      // noop
      debug && console.error(e)
    }
  }
}
export const locationHistory = new Location('search-history');
setTimeout(() => locationHistory.init(), 0);

const addSearchParams = ({ urlParams, searchParamFilter, paramsString }) => {
  for (const [name, value] of urlParams) {
    if (searchParamFilter && !searchParamFilter(name, value)) continue;
    paramsString += `${name}=${value}&`;
  }
  return paramsString;
}

export const adListingRegex =
  /^\/+(?:leasevoorraad(?:\/+(marge-autos|personen-auto-s|bedrijfsauto-s|hybride|elektrisch|7-zitters|pick-ups))?|([^/]+)-lease(?:\/+\2-([^/]+)-lease)?)\/*$/;

export const adListing = (
  { brandSlug, modelSlug, page, filters, search = null, searchParamFilter = null, highlightAd } = {},
  action
) => {
  filters = { ...filters };

  let url = '/leasevoorraad';
  let carType = null;
  if (!brandSlug) {

    if (filters.type) {
      const types = filters.type.split(',');
      if (types.length === 1) {
        carType = carTypeItems.find((i) => i.slug === types[0])?.value;
        delete filters.type;
      }
    } else if (filters.marge) {
      const marge = filters.marge;
      if (marge === 'ja') {
        carType = 'margin';
        delete filters.marge;
      }
    } else if (filters.brandstof === 'e') {
      carType = 'electric';
      delete filters.brandstof;
    } else if (filters.brandstof?.split(",").sort().join(',') === 'h,hb,hd') {
      carType = 'hybrid';
      delete filters.brandstof;
    } else if (filters.stoelen === '7') {
      carType = '7-seats';
      delete filters.stoelen;
    } else if (filters.carrosserie === 'Pick-up') {
      carType = 'pick-ups';
      delete filters.carrosserie;
    }
  }
  if (brandSlug) {
    if (modelSlug) {
      url = `/${brandSlug}-lease/${brandSlug}-${modelSlug}-lease`;
    } else {
      url = `/${brandSlug}-lease`;
    }
  } else if (carType) {
    switch (carType) {
      case 'margin':
        url += '/marge-autos';
        break;
      case 'passenger':
        url += '/personen-auto-s';
        break;
      case 'commercial':
        url += '/bedrijfsauto-s';
        break;
      case 'electric':
        url += '/elektrisch';
        break;
      case 'hybrid':
        url += '/hybride';
        break;
      case '7-seats':
        url += '/7-zitters';
        break;
      case 'pick-ups':
        url += '/pick-ups';
        break;
    }
  }
  if (!brandSlug && modelSlug) {
    filters.model = modelSlug;
  } else if (filters) {
    delete filters.model;
  }
  if (search) {
    let paramsString = '?';
    if (page > 1) {
      paramsString += `p${page}&`;
    }
    const urlParams = new URLSearchParams(search);
    const mySearchParamFilter = (k, v) => {
      if (k.match(pageRegex)) return false;
      if (searchParamFilter) return searchParamFilter(k, v)
      return true;
    }
    paramsString = addSearchParams({
      paramsString,
      urlParams,
      searchParamFilter: mySearchParamFilter
    }).slice(0, -1);
    url += paramsString.toString();
  } else if (page > 1) {
    url += `?p${page}`;
  }
  if (highlightAd) {
    filters['highlight-ad'] = highlightAd;
  }
  if (filters && Object.keys(filters).length) {
    url += `#` + new URLSearchParams(filters).toString();
  }
  return url;
};

export const adRegex =
  /^\/+([^/]+?)(-lease)?\/+(?:\1-)?([^/]+?)\2?\/+variant-([^/]+)\/+occ(\d+)\/*$/;

export const ad = ({ brandSlug, modelSlug, adSlug, adId, search = null, searchParamFilter = null } = {}) => {
  let url = `/${brandSlug}-lease/${brandSlug}-${modelSlug}-lease/variant-${adSlug}/occ${adId}`;
  let paramsString = '?';
  if (search) {
    const urlParams = new URLSearchParams(search);
    paramsString = addSearchParams({ paramsString, urlParams, searchParamFilter }).slice(0, -1);
    url += paramsString.toString();
  }
  return url;
};

const formats = {
  adListing,
  ad,
  404: () => '/pagina-niet-gevonden',
};

const pageRegex = /^p(\d)+$/;
export const locationToStr = (location = null, search = null, hash = null) => {
  if (!location) location = window.location;
  if (!search) search = location.search;
  if (!hash) hash = location.hash;
  let str = location.pathname;
  if (search) str += search.replace(/^\??/, '?');
  if (hash) str += hash.replace(/^#?/, '#');
  return str;
};

const getUrlInfo = (location) => {
  const { pathname, search, hash } = location;
  const adRegexMatch = pathname.match(adRegex);
  if (adRegexMatch) {
    const [, brandSlug, , modelSlug, adSlug, adId] = adRegexMatch;
    const checkUrl = ad({ brandSlug, modelSlug, adSlug, adId, search });
    return {
      type: 'ad',
      brandSlug,
      modelSlug,
      adSlug,
      adId,
      shouldRewrite: checkUrl !== locationToStr(location),
      url: checkUrl,
    };
  }

  const adListingMatch = pathname.match(adListingRegex);
  if (adListingMatch) {
    const [, carType, brandSlug, modelSlug] = adListingMatch;
    let params = search ? queryString.parse(search) : undefined;
    const page = params
      ? Object.keys(params)
        .map((i) => Number(i.match(pageRegex)?.[1]))
        .filter((i) => i)[0]
      : undefined;
    let filters = hash ? queryString.parse(hash) : undefined;

    let highlightAd = filters?.['highlight-ad'];

    if (carType) {
      if (!filters) filters = {};
      const [k, v] = {
        'marge-autos': ['marge', 'ja'],
        'personen-auto-s': ['type', 'p'],
        'bedrijfsauto-s': ['type', 'b'],
        'elektrisch': ['brandstof', 'e'],
        'hybride': ['brandstof', 'h,hd,hb'],
        '7-zitters': ['stoelen', '7'],
        'pick-ups': ['carrosserie', 'Pick-up'],
      }[carType] || [null, null];
      if (k) filters[k] = v;
    }

    const checkUrl = adListing({
      brandSlug,
      modelSlug,
      page: page || undefined,
      filters,
      search
    });

    return {
      type: 'adListing',
      brandSlug: brandSlug || null,
      modelSlug: modelSlug || filters?.model || null,
      page: page || 1,
      filters,
      highlightAd,
      shouldRewrite: checkUrl !== locationToStr(location),
      url: checkUrl,
    };
  }

  return {
    type: 'unknown',
    shouldRewrite: false,
  };
};

export const getNormalizedUrl = (location = null) => {
  if (!location) {
    location = window.location;
  }
  return getUrlInfo(location).url;
};

export const parseUrl = (location = null, { rewrite = null } = {}) => {
  if (!location) {
    location = window.location;
    if (rewrite === null) rewrite = true;
  } else {
    if (rewrite === null) rewrite = false;
  }
  const urlInfo = getUrlInfo(location);
  if (rewrite && urlInfo?.shouldRewrite && !norewrite) {
    debug && console.log('rewrite', urlInfo.url);
    locationHistory.replace(urlInfo.url);
  }
  return urlInfo;
};

export const updateUrl = (
  update,
  { location = null, action = 'replace' } = {}
) => {
  if (!location) location = window.location;
  const { shouldRewrite, ...urlInfo } = getUrlInfo(location);
  const props =
    typeof update === 'function' ? update(urlInfo) : { ...urlInfo, ...update };
  if (props.type === 'unknown' || !formats[props.type]) return;

  const newUrl = formats[props.type]({ search: location.search, ...props }, action);

  if (newUrl === locationToStr(location)) return newUrl;
  if (isPreview || norewrite) return newUrl;
  if (noredirect && action === 'redirect') action = 'push';
  if (action === 'return') {
    return newUrl;
  } else if (action === 'replace') {
    debug && console.log('replace', newUrl);
    locationHistory.replace(newUrl, urlInfo.type);
  } else if (action === 'push') {
    debug && console.log('push', newUrl);
    locationHistory.push(newUrl, urlInfo.type);
  } else if (action === 'redirect') {
    debug && console.log('redirect', newUrl);
    locationHistory.redirect(newUrl, urlInfo.type);
  }
  return newUrl;
};

const slugifyToLength = (str, length) => {
  let slug = slugify(str.replaceAll('|', ' '), { lower: true, strict: true });
  while (slug.length > length) {
    const newSlug = slug.replace(/-[^-]+$/, '');
    if (newSlug === '' || newSlug === slug) return slug.substring(0, length);
    slug = newSlug;
  }
  return slug;
};

export const ensureUrl = () => {
  const urlInfo = parseUrl();
  if (urlInfo.shouldRewrite && !norewrite) {
    debug && console.log('ensured', urlInfo.url);
    locationHistory.replace(urlInfo.url, urlInfo.type);
  }
  if (!window.urlCheckInProgess)
    if (urlInfo.type === 'ad')
      (async () => {
        window.urlCheckInProgess = true;
        try {
          let ad = false;
          try {
            ad = await getAdDetail(urlInfo.adId);
          } catch (e) {
            updateUrl(
              {
                type: 404,
              },
              { action: 'redirect' }
            );
            return;
          }
          const [brandSlug, modelSlug] = await Promise.all([
            getHubDbBrand(ad.make.id).then((i) => i?.slug),
            getHubDbModel(ad.model.id).then((i) => i?.slug),
          ]);
          updateUrl(getAdUrlParts({ ad, brandSlug, modelSlug }), {
            action: 'replace',
          });
        } finally {
          window.urlCheckInProgess = false;
        }
      })();
  return urlInfo;
};

export const getAdUrlParts = ({ ad, brandSlug = null, modelSlug = null }) => {
  return {
    brandSlug:
      brandSlug ||
      slugify(ad.make.name || 'onbekend merk', { lower: true, strict: true }),
    modelSlug:
      modelSlug ||
      slugify(ad.model.name || 'onbekend model', { lower: true, strict: true }),
    adSlug: slugifyToLength(
      `${ad.description || ad.title || 'onbekende variant'}`,
      50
    ),
    adId: ad.id,
  };
};
