import { useMemo } from "react";
import { useCurrentRefinements } from "react-instantsearch-hooks-web";

/**
 * Adds items from the current refinements to the list of items when they're not in the current filtered set anymore.
 * @param {*} attribute the attribute to add items for
 * @param {*} otherItems the list of items from the available facet values
 * @returns 
 */
export const useRefinedItems = (attribute, otherItems = []) => {
    // get the current refinements for this attribute
    const { items: refined } = useCurrentRefinements({
        includedAttributes: [attribute],
    });

    // flatten the list of current refinements
    const refinements = useMemo(
        () =>
            refined.reduce((a, i) => {
                a.push(...i.refinements.map(i => ({ ...i, count: i.count || 0, isRefined: true })))
                return a;
            }, []).filter(i => i.attribute === attribute),
        [refined]
    )

    // merge the lists together
    const allItems = useMemo(
        () => {
            const availableIds = Object.fromEntries(otherItems.map(i => [i.value, true]))
            const prepend = refinements.filter(i => !availableIds[i.value])
            return [...prepend, ...otherItems]
        },
        [otherItems, refinements]
    )

    return allItems;
}